.modal-group-content {
  position: absolute;
  top: 7%;
  width: 100%;
  height: 50%;
  border: none;
  background-color: var(--seafoam-blue);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  outline: none;
}
.modal-group-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 9999;
}

.modal-edit-device-content {
  position: absolute;
  overflow-y: hidden;
  overflow-x: hidden;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 50%;
  border: none;
  background-color: #ffff;
  transform: translate(-50%, -50%);
  -webkit-overflow-scrolling: touch;
  outline: none;
  min-height: 550px;
  min-width: 815px;
}
.modal-edit-device-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 9999;
}

.modal-columns-content {
  position: absolute;
  overflow-y: scroll;
  overflow-x: hidden;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  width: 350px;
  height: 680px;
  border: none;
  background-color: #ffffff;
  -webkit-overflow-scrolling: touch;
  outline: none;
}
.modal-columns-skip-content {
  position: absolute;
  overflow-y: scroll;
  overflow-x: hidden;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  width: 350px;
  height: 430px;
  border: none;
  background-color: #ffffff;
  -webkit-overflow-scrolling: touch;
  outline: none;
}
.modal-columns-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 9999;
}

.modal-status-content {
  position: absolute;
  top: 15%;
  right: 57.5px;
  left: 57.5px;
  width: 245px;
  height: 480px;
  border: none;
  background-color: #ffffff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  outline: none;
}
.modal-fillLevel-content {
  position: absolute;
  top: 15%;
  right: 7.5px;
  left: 7.5px;
  width: 345px;
  height: 480px;
  border: none;
  background-color: #ffffff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  outline: none;
}
.modal-status-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.push {
  margin-left: auto;
}
@media only screen and (min-width: 320px) {
  .modal-status-content {
    right: 37.5px;
    left: 37.5px;
  }
  .modal-fillLevel-content {
    right: 0px;
    left: 0px;
    width: 320px;
  }
}
@media only screen and (min-width: 360px) {
  .modal-status-content {
    right: 57.5px;
    left: 57.5px;
  }
  .modal-fillLevel-content {
    right: 7.5px;
    left: 7.5px;
    width: 345px;
  }
}
@media only screen and (min-width: 375px) {
  .modal-status-content {
    right: 65px;
    left: 65px;
  }
  .modal-fillLevel-content {
    right: 15px;
    left: 15px;
    width: 345px;
  }
}

@media only screen and (min-width: 411px) {
  .modal-status-content {
    right: 83px;
    left: 83px;
  }
  .modal-fillLevel-content {
    right: 33px;
    left: 33px;
    width: 345px;
  }
}

@media only screen and (min-width: 414px) {
  .modal-status-content {
    right: 84.5px;
    left: 84.5px;
  }
  .modal-fillLevel-content {
    right: 34.5px;
    left: 34.5px;
    width: 345px;
  }
}

@media only screen and (min-width: 768px) {
  .modal-group-content {
    width: 45.2%;
    height: 72.4%;
  }
}
