@font-face {
  font-family: "Europress";
  src: url("../assets/font/europress.eot?81277103#iefix")
      format("embedded-opentype"),
    url("../assets/font/europress.woff2?81277103") format("woff2"),
    url("../assets/font/europress.woff?81277103") format("woff"),
    url("../assets/font/europress.ttf?81277103") format("truetype"),
    url("../assets/font/europress.svg?81277103#europress") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'europress';
    src: url('../font/europress.svg?81277103#europress') format('svg');
  }
}
*/

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "europress";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-search:before {
  content: "\e800";
} /* '' */
.icon-user:before {
  content: "\e801";
} /* '' */
.icon-tree:before {
  content: "\e802";
} /* '' */
.icon-menu:before {
  content: "\e803";
} /* '' */
.icon-arrow-down:before {
  content: "\e804";
} /* '' */
