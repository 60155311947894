/* Small 50px spinner */
.lds-ring-small {
  display: inline-block;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 64px;
  height: 64px;
}
.lds-ring-small div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid rgb(94, 124, 189);
  border-radius: 50%;
  animation: lds-ring-small 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--col-214269) transparent transparent transparent;
}
.lds-ring-small div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring-small div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring-small div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring-small {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Large 200px spinner */
.lds-ring {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-100px, -100px);
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 200px;
  height: 200px;
  margin: 22px;
  border: 22px solid rgb(94, 124, 189);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--col-214269) transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
