@import url("./css/europress.css");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,700");
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", "Roboto",
    "Europress", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

html,
#root {
  height: 100%;
}

/*
Use non-annoying box-sizing, which makes defining layouts easier.

This way of using it allows overwriting it for some elements if
desired, and also applies to pseudo-elements.
*/
html {
  box-sizing: border-box;
  font-size: 16px;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
